*{
  padding: 0;
  margin: 0;
}
@font-face{
  font-family: DMS;
  src: url('assets/fonts/DMSerifDisplay-Regular.ttf');
}
@font-face{
  font-family: O-extrabold;
  src: url('assets/fonts/OpenSans-ExtraBold.ttf');
}
@font-face{
  font-family: O-bold;
  src: url('assets/fonts/OpenSans-Bold.ttf');
}
@font-face{
  font-family: O-medium;
  src: url('assets/fonts/OpenSans-Medium.ttf');
}
@font-face{
  font-family: O-regular;
  src: url('assets/fonts/OpenSans-Regular.ttf');
}
@font-face{
  font-family: O-light;
  src: url('assets/fonts/OpenSans-Light.ttf');
}
@font-face{
  font-family: O-semibold;
  src: url('assets/fonts/OpenSans-SemiBold.ttf');
}

h1, h2{
  font-family:O-extrabold;
}
h3, h4{
  font-family:O-bold;
}
h5{
  font-family:O-Medium;
}
h6{
  font-family:O-semibold;
}
p, a, small{
  font-family: O-regular;
}
.main-app{
  background: linear-gradient(to right, #f7efeb, #f2f4f7, #f7efeb);
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow-x:hidden;
}

.btn-green{
  background-color:#0096a0 !important;
  color:#fff !important;
  font-family:O-bold;
}
.btn-green-outline{
  background-color:#fff !important;
  border: 1px solid #0096a0;
  color:#0096a0 !important;
  font-family:O-bold;
}

.nice-bg h5, h5{
  font-family: DMS;
  line-height: 30px !important;
}
.nice-bg h6{
  color: #0096a0 !important;
}
.nice-bg p{
  padding: 3px !important;
  margin: 3px !important;
}

.card{
  border-radius: 80px 0px 0px 80px !important;
  width: 102%;
}

form{
  min-height: 400px;
  position: relative;
}
form::before{
  position: absolute;
  content:"";
  height: 100%;
  border-left: 1px solid #000;
  left:-40px;
}
form fieldset{
  border: 1px solid #000 !important;
  margin: 20px auto;
}
legend{
  float: none;
  margin: 0 !important;
  padding:0px 5px !important;
  width: unset !important;
  font-family: O-regular;
  font-size: 14px;
  color: #344663;
}
form input, select{
  border: none !important;
  font: 14px O-regular;
  /* padding-bottom: 0px; */
  margin-left: 5px;
  background-color: transparent;
}
form select option{
  font:13px O-regular;
}
form .css-yk16xz-control{
  border: none !important;
  font-family: O-regular;
  background-color: transparent;
}
form .css-2b097c-container{
  width:100% !important;
}
form input::placeholder{
  color: #a3a2a2;
  padding: 0 !important;
  margin: 0 !important;
}
form input:focus, form select:focus{
  outline: none !important;
}
form textarea, form textarea:focus{
  border: none;
  outline: none !important;
}

.card .title-area {
  display: flex;
  position: relative;
}
.card .title-area::after {
  position: absolute;
  content: "";
  height: 50%;
  top: 25px;
  left: 10px;
  border-left: 1px solid #000;
}
.card .title-area.done::after {
  position: absolute;
  content: "";
  height: 50%;
  top: 25px;
  left: 10px;
  border-left: 1px solid #0096a0;
}
.card .title-area span p{
  width: 20px !important;
  height:20px !important;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #000 ;
  margin-right: 10px !important;
  padding: 0px;
  color:#000;
}
.card .title-area i{
  width: 20px !important;
  height:20px !important;
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #0096a0 ;
  margin-right: 10px;
  color:#0096a0;
}
.card .title-area p{
  padding: 0 !important;
  margin: 0 !important;
  font: 13px O-semibold;
}
.card .title-area small{
  font-size: 13px;
}

/* responsiveness */
@media screen and (max-width:1000px) {
  form::before{
    display: none;
  }
}
@media screen and (max-width:600px) {
  .card{
    border-radius: 50px 50px 0px 0px !important;
    margin-top: 50px;
    width: 100%;
  }
}
